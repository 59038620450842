import React, {useContext} from 'react';
import {Row, Text} from '../../Widgets';
import styled from 'styled-components';
import {message, Upload} from 'antd';
import {USER_TYPE} from '../../dictionary';
import {Context} from '../../AppContext';
const config = require('../../data.json');

export default function Information() {
  const app = useContext(Context);
  const {profile} = app.state;

  async function editProfile({file}) {
    if (file) {
      app.actions.setLoading(true);
      try {
        await app.actions.editProfile({
          avatar: file,
        });
        await app.actions.getProfile();
        message.success('更換頭像成功');
      } catch (err) {
        console.warn(err);
        message.error('更換頭像錯誤');
      }
      app.actions.setLoading(false);
    }
  }

  return (
    <Row margin="0 0 20px 0">
      <Avatar
        avatar={
          profile.avatar === ''
            ? '/images/avatar-default.png'
            : `${profile.avatar}` || `${profile.social_avatar}`
        }>
        <Upload beforeUpload={() => false} fileList={[]} onChange={editProfile}>
          <AvatarEdit src="/images/avatar-edit-icon.svg" />
        </Upload>
      </Avatar>
      <div style={{flex: 1, marginLeft: 20}}>
        <Text>{profile.name}</Text>
        <Row margin="0">
          <Text
            size="xs"
            color={config.colors.textThird}
            style={{marginRight: 10}}>
            #{profile.id}{' '}
          </Text>
          <Text
            size="xs"
            color={config.colors.textThird}
            style={{marginRight: 10}}>
            {{
              google: 'Google 登入',
              facebook: 'FB 登入',
            }[profile.social_type] || '一般信箱登入'}{' '}
          </Text>
          <Text size="xs" color={config.colors.textThird}>
            {USER_TYPE[profile.user_type] || ''}
          </Text>
        </Row>
        <Row margin="0">
          <Text size="xs" color={config.colors.textThird}>
            預儲點數：
          </Text>
          <Text size="xs" color={config.colors.main}>
            {profile.credits} 點
          </Text>
        </Row>
        <Row margin="0">
          <Text size="xs" color={config.colors.textThird}>
            紅利金：
          </Text>
          <Text size="xs" color={config.colors.main}>
            {profile.bonus} 元
          </Text>
        </Row>
      </div>
    </Row>
  );
}

const Avatar = styled.div`
  background-color: #dcdddd;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-image: url(${(props) => props.avatar});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
`;

const AvatarEdit = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;
