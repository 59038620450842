const entries = [
  {
    name: 'orders',
    label: '訂單總覽',
    subtitle: 'Orders',
    entries: [
      {name: 'order', label: '全部訂單'},
      {name: 'orderWaiting', label: '待付款訂單'},
      {name: 'orderItemRejected', label: '檔案未合格訂單'},
      {name: 'orderFinished', label: '付款已完成訂單'},
    ],
  },
  {
    name: 'account_manage',
    label: '帳戶管理',
    subtitle: 'Account',
    entries: [
      {name: 'depositCredit', label: '點數加值'},
      {name: 'depositRecord', label: '加值訂單'},
      {name: 'creditRecord', label: '點數交易記錄'},
    ],
  },
  {
    name: 'profile',
    label: '會員資料',
    subtitle: 'Profile',
    entries: [
      {name: 'myProfile', label: '基本資料'},
      {name: 'changePw', label: '修改登入密碼'},
      {name: 'reqEnt', label: '申請成為企業會員'},
      {name: 'reqMonthly', label: '申請成為企業月結會員'},
      // { name: "address", label: "訂單常用地址" },
    ],
  },
  {
    name: 'monthly_manage',
    label: '月結服務',
    subtitle: 'Monthly',
    entries: [{name: 'monthlyRecord', label: '月結交易紀錄'}],
    // hidden: !isEnt(profile),
  },

  {
    name: 'logout',
    subtitle: 'Log out',
    label: '登出',
    type: 'function',
  },
];

export default entries;
