import React, {useState} from 'react';
import {Row, Col} from 'antd';
import Breadcrumb from './Breadcrumb';
import TabContainer from './TabContainer';
import Information from './Information';
import Navbar from './Navbar';
import useProfileLayout from '../../hooks/use-profile-layout';

function ProfileLayout({pathname, children}) {
  const {selected, setSelected, parent} = useProfileLayout({pathname});

  return (
    <Row gutter={20}>
      <Col md={4} xs={24}>
        <Navbar pathname={pathname} />
      </Col>
      <Col md={1} xs={24} style={{height: 20}}></Col>
      <Col md={19} xs={24}>
        <Breadcrumb entry={parent} leafNode={selected} />
        <Information />
        <TabContainer
          entry={parent}
          leafNode={selected}
          onTabClick={(entry) => setSelected(entry)}>
          {children({selected})}
        </TabContainer>
      </Col>
    </Row>
  );
}

export default ProfileLayout;
export {Breadcrumb, TabContainer, Information, Navbar};
