import React, {useContext, useEffect, useState} from 'react';
import {Context} from '../AppContext';
import {FormExpander, Row, Label, Input, Button, ErrorHint} from '../Widgets';
import {message} from 'antd';
import {ErrProfileFormat} from '../errors';
import formatValidator from '../Utils/formatValidator';

const {isNotEmpty, isNumeric} = formatValidator;

export default function RequestEntPanel(props) {
  const app = useContext(Context);
  const {profile} = app.state;
  const [data, _setData] = useState({});
  const [validate, setValidate] = useState(false);
  const editable = !profile.req_ent;

  function setData(obj) {
    _setData((pre) => ({...pre, ...obj}));
  }

  useEffect(() => {
    if (profile) {
      try {
        _setData(JSON.parse(profile.data));
      } catch (ex) {}
    }
  }, [profile]);

  const valid = () => {
    setValidate(true);
    if (
      !isNotEmpty(data.ent_name) ||
      !isNotEmpty(data.gui_number) ||
      !isNotEmpty(data.ent_tel) ||
      !isNotEmpty(data.ent_address)
    ) {
      throw new ErrProfileFormat('欄位不能為空');
    } else if (!isNumeric(data.gui_number) || !isNumeric(data.ent_tel)) {
      throw new ErrProfileFormat('格式錯誤');
    }
  };

  const submit = async () => {
    try {
      valid();

      let req_data = {};
      if (profile.user_type === 'vip') {
        req_data = {req_ent_vip: true};
      } else if (profile.user_type === 'normal') {
        req_data = {req_ent: true};
      }
      await app.actions.editProfile({
        ...req_data,
        data: JSON.stringify(data),
      });
      await app.actions.getProfile();
      message.success('修改成功');
    } catch (err) {
      console.warn(err);
      if (err instanceof ErrProfileFormat) {
        message.error(err.message);
      } else {
        message.error('修改錯誤');
      }
    }
  };

  return (
    <div>
      <FormExpander title="企業會員申請表" closable={false}>
        <Row>
          <Label flex="0 0 70px">公司名稱*</Label>
          <Input
            disabled={!editable}
            placeholder="公司名稱"
            value={data.ent_name || ''}
            onChange={(e) => setData({ent_name: e.target.value})}
          />
          {validate && !isNotEmpty(data.ent_name) && (
            <ErrorHint>公司名稱不能為空</ErrorHint>
          )}
        </Row>
        <Row>
          <Label flex="0 0 70px">統一編號*</Label>
          <Input
            disabled={!editable}
            value={data.gui_number || ''}
            placeholder="統一編號"
            onChange={(e) => setData({gui_number: e.target.value})}
          />
          {validate && !isNotEmpty(data.gui_number) && (
            <ErrorHint>統一編號不能為空</ErrorHint>
          )}
        </Row>
        <Row>
          <Label flex="0 0 70px">公司電話*</Label>
          <Input
            disabled={!editable}
            value={data.ent_tel || ''}
            placeholder="公司電話"
            onChange={(e) => setData({ent_tel: e.target.value})}
          />
          <Input
            disabled={!editable}
            placeholder="分機"
            type="short"
            value={data.tel_ext || ''}
            onChange={(e) => setData({tel_ext: e.target.value})}
          />

          {validate && !isNotEmpty(data.ent_tel) ? (
            <ErrorHint>公司電話不能為空</ErrorHint>
          ) : !isNumeric(data.ent_tel) ? (
            <ErrorHint>請確認公司電話格式</ErrorHint>
          ) : null}
        </Row>
        <Row>
          <Label flex="0 0 70px">公司地址*</Label>
          <Input
            disabled={!editable}
            placeholder="公司地址"
            type="long"
            value={data.ent_address || ''}
            onChange={(e) => setData({ent_address: e.target.value})}
          />
          {validate && !isNotEmpty(data.ent_address) && (
            <ErrorHint>公司地址不能為空</ErrorHint>
          )}
        </Row>

        <Row margin="0 0 30px 0">
          <div style={{flex: 1}} />
          {editable ? (
            <Button onClick={submit}>送出企業會員申請</Button>
          ) : (
            <Button disabled>已送出企業會員申請</Button>
          )}
        </Row>
      </FormExpander>
    </div>
  );
}
