import React, {Fragment, useContext} from 'react';
import {Row} from '../../Widgets';
import styled from 'styled-components';
import {Context} from '../../AppContext';
const config = require('../../data.json');

export default function TabContainer({
  entry,
  leafNode,
  children,
  onTabClick = () => {},
}) {
  const app = useContext(Context);
  const {profile} = app.state;

  if (entry && Array.isArray(entry.entries)) {
    return (
      <Fragment>
        <Row
          style={{borderTop: 'solid 1px ' + config.colors.border}}
          margin="0">
          {entry.entries
            .filter((e) => {
              if (e.name === 'reqEnt') {
                return (
                  profile.user_type === 'normal' || profile.user_type === 'vip'
                );
              }
              if (e.name === 'reqMonthly') {
                return (
                  profile.user_type === 'enterprise' ||
                  profile.user_type === 'ent_vip'
                );
              }

              return true;
            })
            .map((e) => (
              <TabItem
                key={e.name}
                selected={leafNode.name === e.name}
                onClick={() => onTabClick(e)}>
                {e.label}
              </TabItem>
            ))}
        </Row>
        <TabContent>{children}</TabContent>
      </Fragment>
    );
  }
  return null;
}

const TabItem = styled.div`
  padding: 20px;
  text-align: center;
  color: ${(props) =>
    props.selected ? config.colors.main : config.colors.text};
  cursor: default;
  transition: all 0.2s linear;

  @media only screen and (max-width: ${config.breakpoints.sm}px) {
    padding: 10px 5px;
    flex: 1 1 auto;
  }
`;

const TabContent = styled.div`
  min-height: 200px;
`;
