import React, {useContext, useEffect, useState} from 'react';
import {Context} from '../AppContext';
import {
  FormExpander,
  Row,
  Label,
  Input,
  Button,
  Checkbox,
  Hint,
  Text,
  Divider,
  Select,
  SelectOption,
} from '../Widgets';
import {message} from 'antd';
import {ErrProfileFormat} from '../errors';
import styled from 'styled-components';
const appConfig = require('../data.json');

export default function RequestMonthlyPanel(props) {
  const app = useContext(Context);
  const {profile} = app.state;
  const [data, _setData] = useState({
    industry_type: '文化創意／藝文相關',
  });
  const [agree, setAgree] = useState(false);
  const editable = !profile.req_ent_monthly;

  function setData(obj) {
    _setData((pre) => ({...pre, ...obj}));
  }

  useEffect(() => {
    if (profile) {
      try {
        let parsed = JSON.parse(profile.monthly_data);
        setData(parsed);
      } catch (ex) {}
    }
  }, [profile]);

  const submit = async () => {
    try {
      if (!agree) {
        throw new ErrProfileFormat('請先閱讀完說明');
      }

      let req_data = {};
      if (profile.user_type === 'enterprise') {
        req_data = {req_ent_monthly: true};
      } else if (profile.user_type === 'ent_vip') {
        req_data = {req_ent_vip_monthly: true};
      }
      await app.actions.editProfile({
        ...req_data,
        monthly_data: JSON.stringify(data),
      });
      await app.actions.getProfile();
    } catch (err) {
      console.warn(err);
      if (err instanceof ErrProfileFormat) {
        message.error(err.message);
      } else {
        message.error('申請發生錯誤');
      }
    }
  };

  return (
    <div>
      <Description>
        <h4>月結會員專屬服務</h4>
        <div className="content">
          <p>• 運費每月結算一次，提供您方便又省時的服務。</p>
          <p>• 提供您每月帳單報表資料。</p>
          <p>• 享有30天還款期，提高企業資金效益。</p>
          <p>• 優先獲取平台相關業務信息或資料。</p>
          <p>• 優先體驗和試用新服務。</p>
          <p>• 擁有比普通客戶更多、更方便的增值服務選擇。</p>
        </div>
        <h4>申請加入條件</h4>
        <div className="content">
          <p>• 申請開通月結帳戶前，必須先擁有本服務企業會員帳號。</p>
          <p>
            •
            月結會員僅限於公司、商行、工廠及協會等具有統一編號之營利事業或財團法人。
          </p>
          <p>• 月結會員申請時填寫之相關資料使用適用於本服務之隱私權政策。</p>
        </div>
        <h4>申請準備資料</h4>
        <div className="content">
          <p>
            若您符合以上申請條件，請填寫『企業月結會員申請』資料，並備妥以下資料檔案掃描檔或影本：
          </p>
          <p>1. 勾選同意下方月結服務說明，並填寫『月結會員申請』資料</p>
          <p>
            2.
            公司具有營業登記、商業登記、稅籍登記、財團法人及社團法人的登記(以上檔案擇一)，需持有具法律效力登記證明的營業執照。
          </p>
          <p>3. 提供公司行號支存帳號資掃描檔或影本。</p>
          <p>以上資料送審後，我們將會盡快審核您提出的申請。</p>
        </div>
        <h4>如何變更月結帳戶資料?</h4>
        <div className="content">
          <p>
            如您有財務資料或公司基本資料異動需求，請寄送申請變更月結會員資料寄送電子郵件：
            {appConfig.information.email}，
          </p>
          <p>
            或請於平台服務時間來電客服專線：{appConfig.information.tel}
            ，由專人為您服務修改。
          </p>
        </div>
      </Description>
      <FormExpander title="月結服務申請表" closable={false}>
        <Row>
          <Label>會員名稱</Label>
          <Input
            disabled={!editable}
            value={data.ent_name}
            placeholder="會員名稱"
            onChange={(e) => setData({ent_name: e.target.value})}
          />
          <Hint>＊企業會員之名稱即公司聯絡人名稱</Hint>
        </Row>
        <Row>
          <Label>公司抬頭</Label>
          <Input
            disabled={!editable}
            value={data.company_title}
            placeholder="公司抬頭"
            onChange={(e) => setData({company_title: e.target.value})}
          />
        </Row>
        <Row>
          <Label>公司統編</Label>
          <Input
            disabled={!editable}
            value={data.gui_number}
            placeholder="公司統編"
            onChange={(e) => setData({gui_number: e.target.value})}
          />
        </Row>
        <Row>
          <Label>電子信箱</Label>
          <Input
            disabled={!editable}
            value={data.email}
            placeholder="電子信箱"
            onChange={(e) => setData({email: e.target.value})}
          />
        </Row>
        <Row>
          <Label>產業類型</Label>
          <Select
            disabled={!editable}
            value={data.industry_type}
            onChange={(value) => setData({industry_type: value})}>
            <SelectOption value="文化創意／藝文相關">
              文化創意／藝文相關
            </SelectOption>
            <SelectOption value="印刷同業">印刷同業</SelectOption>
            <SelectOption value="廣告行銷">廣告行銷</SelectOption>
            <SelectOption value="設計公司">設計公司</SelectOption>
            <SelectOption value="個人工作室／SOHO">
              個人工作室／SOHO
            </SelectOption>
            <SelectOption value="其他">其他</SelectOption>
          </Select>
        </Row>
        <Row>
          <Label>公司電話</Label>
          <Input
            disabled={!editable}
            value={data.ent_tel}
            placeholder="公司電話"
            onChange={(e) => setData({ent_tel: e.target.value})}
          />
        </Row>
        <Row>
          <Label>傳真號碼</Label>
          <Input
            disabled={!editable}
            value={data.fax}
            placeholder="傳真號碼"
            onChange={(e) => setData({fax: e.target.value})}
          />
        </Row>
        <Row>
          <Label>公司地址</Label>
          <Input
            disabled={!editable}
            value={data.address}
            placeholder="公司地址"
            onChange={(e) => setData({address: e.target.value})}
          />
        </Row>
        {/* <Row>
            <Label>發票形式</Label>
            <Select
              disabled={!editable}
              value={data.receipt_type}
              onChange={value => setData({ receipt_type: value)}
            >}
              <SelectOption value="二聯">二聯</SelectOption>
              <SelectOption value="三聯">三聯</SelectOption>
            </Select>
          </Row>
          <Row>
            <Label>公司負責人 手機 / 電話</Label>
            <Input
              disabled={!editable}
              value={data.principal_tel}
              onChange={e =>
                setData({ principal_tel: e.target.value})
              }
            />
          </Row>
          <Row>
            <Label>聯絡人 手機 / 電話</Label>
            <Input
              disabled={!editable}
              value={data.contact_tel}
              onChange={e => setData({ contact_tel: e.target.value})}
            />
          </Row> */}
        <Divider />
        <Row wrap="nowrap" style={{alignItems: 'flex-start'}}>
          <Checkbox
            style={{marginRight: 10}}
            defaultChecked={false}
            onChange={(e) => setAgree(e.target.checked)}
          />
          <div style={{marginLeft: 10}}>
            <Text size="sm">
              申請前請務必至詳細閱讀『 客服中心{'>'}企業會員交易須知
              』，以免影響您的權益謝謝。
            </Text>
            <Text size="sm">
              我已閱讀以上月結服務說明，同意遵守『 月結客戶付款注意事項
              』，並申請成為服務平台之企業月結會員。
            </Text>
          </div>
        </Row>
        <Row margin="0 0 30px 0">
          <div style={{flex: 1}} />
          <Button disabled={!editable} onClick={submit}>
            {editable ? '送出月結會員申請' : '已送出月結會員申請'}
          </Button>
        </Row>
      </FormExpander>
    </div>
  );
}

const Description = styled.div`
  margin: 20px;

  & > h4 {
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 5px;
  }

  & > .content {
    margin-bottom: 20px;
    & > p {
      margin-bottom: 0px;
      font-size: 14px;
    }
  }
`;
