import React, {useContext, useEffect, useState} from 'react';
import {Context} from '../AppContext';
import InvoiceForm from './InvoiceForm';
import {FormExpander, Row, Label, Input, Hint, Button} from '../Widgets';
import {message} from 'antd';
import {ErrProfileFormat, ErrInvoiceFormat} from '../errors';
import formatValidator from '../Utils/formatValidator';
import invoiceValidator from '../Utils/invoice-validator';
import {isEnt, isMonthly} from '../Utils/UserUtil';

const {isNotEmpty, isMobileNumber} = formatValidator;

const GENERAL_FIELD = [
  // { name: "avatar", type: "file", label: "會員頭像" },
  {name: 'name', type: 'text', label: '你的名稱'},
  {name: 'phone', type: 'text', label: '聯絡電話'},
  {name: 'address', type: 'textarea', label: '聯絡地址'},
  // { name: "email", type: "text", label: "電子信箱" }, can not edit email it's account
];

const ENTERPRISE_FIELD = [
  {name: 'ent_name', type: 'text', label: '公司名稱'},
  {name: 'gui_number', type: 'text', label: '統一編號'},
  {
    name: 'ent_tel',
    type: 'text',
    label: '公司電話',
    children: [{name: 'tel_ext', type: 'text', label: '分機'}],
  },
  {name: 'ent_address', type: 'text', label: '公司地址'},
];

function errorHandler(err) {
  console.warn(err);
  if (err instanceof ErrProfileFormat || err instanceof ErrInvoiceFormat) {
    message.error(err.message);
  } else {
    message.error('修改失敗');
  }
}

export default function ProfileForm(props) {
  const app = useContext(Context);
  const {profile, cart} = app.state;
  const [values, _setValues] = useState();
  const [config, setConfig] = useState(); //only invoiceConfig
  const is_ent = isEnt(profile) || isMonthly(profile);

  function setValues(obj) {
    _setValues((pre) => ({...pre, ...obj}));
  }

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      try {
        //profile initialization
        let values = {};

        if (profile) {
          let {name, phone, address, data, uni_numbers} = profile;
          values = {
            name,
            phone,
            address,
          };

          if (is_ent) {
            let entData = {};
            try {
              entData = JSON.parse(values.data);
            } catch (error) {
              throw new ErrProfileFormat('取得企業資訊錯誤');
            }
            entData.values = {
              ...values,
              ...entData,
              gui_number: entData.gui_number || uni_numbers,
            };
          }
        }
        setValues(values);
      } catch (ex) {
        errorHandler(ex);
      }

      try {
        //invoice initailization
        if (cart && cart.config.invoiceConfig) {
          setConfig(cart.config.invoiceConfig);
        } else {
          setConfig({
            invoice_type: 'two_copies',
            invoice_subtype: 'ezpay_vehicle',
            invoice_detail: '',

            citizen_personal_certificate_code: '', //自然人憑證條碼(16碼)
            mobile_vehicle_code: '', //手機載具(8碼)
            ezpay_vehicle_code: '',

            gui_number: '',
            company_title: '',

            donate_foundation_gui: '',
            love_code: '',
          });
        }
      } catch (ex) {
        errorHandler(ex);
      }
      app.actions.setLoading(false);
    })();
  }, [profile, app.actions, cart, is_ent]);

  const valid = (values) => {
    let {name, phone, address} = values;
    if (!isNotEmpty(name) || !isNotEmpty(phone) || !isNotEmpty(address)) {
      throw new ErrProfileFormat('你的名字、聯絡電話、聯絡地址必須填寫。');
    }

    if (!isMobileNumber(phone)) {
      throw new ErrProfileFormat('聯絡電話必須為手機格式');
    }
  };

  const editProfile = async () => {
    app.actions.setLoading(true);

    let _values = {...values};
    if (is_ent) {
      _values.data = JSON.stringify({
        ent_name: values.ent_name,
        gui_number: values.gui_number,
        ent_tel: values.ent_tel,
        tel_ext: values.tel_ext,
        ent_address: values.ent_address,
      });
    }
    delete _values.bonus;

    try {
      valid(_values);
      await app.actions.editProfile(_values);
      message.success('修改成功');
      app.actions.getProfile();
    } catch (err) {
      errorHandler(err);
    }

    app.actions.setLoading(false);
  };

  const editInvoice = async () => {
    app.actions.setLoading(true);
    try {
      invoiceValidator(config);

      await app.actions.editConfig({invoiceConfig: config});
      message.success('修改成功');
    } catch (ex) {
      errorHandler(ex);
    }
    app.actions.setLoading(false);
  };

  return (
    <div>
      <FormExpander title="會員資料" closable={false}>
        {values &&
          GENERAL_FIELD.map((field, idx) => (
            <Row key={idx}>
              <Label>{field.label}</Label>
              <Input
                placeholder={field.label}
                value={values[field.name]}
                onChange={(e) => setValues({[field.name]: e.target.value})}
              />
              {field.name === 'name' && (
                <Hint>＊會員名稱建議填寫本名，避免造成無法取件之情形。</Hint>
              )}
            </Row>
          ))}

        {values &&
          is_ent &&
          ENTERPRISE_FIELD.map((field, idx) => (
            <Row key={idx}>
              <Label>{field.label}</Label>
              <Input
                placeholder={field.label}
                value={values[field.name]}
                onChange={(e) => setValues({[field.name]: e.target.value})}
              />
              {field.children &&
                field.children.map((child, idx) => (
                  <Input
                    key={idx}
                    placeholder={child.label}
                    value={values[child.name]}
                    onChange={(e) => setValues({[field.name]: e.target.value})}
                  />
                ))}
            </Row>
          ))}
        <Row margin="0 0 30px 0">
          <div style={{flex: 1}} />
          <Button onClick={editProfile}>修改會員資訊</Button>
        </Row>
      </FormExpander>

      <FormExpander title="發票資料" closable={false}>
        {config && (
          <InvoiceForm
            config={config}
            setConfig={(value) => setConfig(value)}
          />
        )}
        <Row margin="0 0 30px 0">
          <div style={{flex: 1}} />
          <Button disabled={!config} onClick={editInvoice}>
            修改發票資訊
          </Button>
        </Row>
      </FormExpander>
    </div>
  );
}
