import React, {useState} from 'react';
import entries from '../Components/ProfileLayout/entries';

export default function useProfileLayout({pathname}) {
  const parent = entries.find((e) => isActice(e.name, pathname));
  const [selected, setSelected] = useState(parent.entries[0]);

  function isActice(url, pathname = '') {
    console.log('pathname', pathname);

    return pathname.replace(/\//g, '') === url.replace(/\//g, '');
  }

  return {
    selected,
    setSelected,
    parent,
  };
}
